import Header from "../component/header/Header"
import Grid from "../component/grid/Grid"
import { useEffect } from "react"
import GridFooter from "../component/grid/GridFooter"
import Footer from "../component/footer/Footer"
import service1 from '../image/service1.png'
import service2 from '../image/service2.png'
import service3 from '../image/service3.png'
import service4 from '../image/service4.png'
import service5 from '../image/service5.png'
import service6 from '../image/service6.png'
import service7 from '../image/service7.png'
import service8 from '../image/service8.png'
import service9 from '../image/service9.jpg'
import service10 from '../image/service10.jpg'
import service from '../image/service.png'
import { useTranslation } from "react-i18next"

export default function Service(){    
    
useEffect(() => {
    
    const id = window.location.hash.substr(1);

    setTimeout(() => {
        if (id) {
            //alert(id)
    
            window.scrollTo(0, 0); 
           const element = document.getElementById(id)
           //alert(element)
            element.scrollIntoView({});
            const currentScroll = window.scrollY; // Get current scroll position
            const updatedScroll = (currentScroll - 150); // Subtract 50px
    
            // Update the scroll position (depending on your needs)
            //window.scrollTo(0, updatedScroll); // Scroll to the new position (smooth scrolling not included) 
            window.scrollTo({top:updatedScroll,behavior: 'smooth'});
        }else{
            window.scrollTo(0, 0); // Scroll to the new position (smooth scrolling not included)
        }
    }, 100);
    
}, []);

const {t} = useTranslation()


    return(
        <div> 
            
            <Header image={service}  titre1={t('nos')} titre2={t('services')} menu="service"></Header>
                
            <div id="service1" className="animate-fade-up animate-duration-[3500ms]"><Grid  image = {service1} text={t('text1')} titre={t('service1')} ></Grid></div>
            <div id="service2" className="animate-fade-up animate-duration-[3500ms]"><Grid  image = {service2} text={t('text2')} titre={t('service2')}></Grid></div>
            <div id="service3" className="animate-fade-up animate-duration-[3500ms]"><Grid  image = {service3} text={t('text3')} titre={t('service3')}></Grid></div>
            <div id="service4" className="animate-fade-up animate-duration-[3500ms]"><Grid  image = {service4} text={t('text4')} titre={t('service4')}></Grid></div>
            <div id="service5" className="animate-fade-up animate-duration-[3500ms]"><Grid  image = {service5} text= {t('text5')} titre={t('service5')}></Grid></div>
            <div id="service6" className="animate-fade-up animate-duration-[3500ms]"><Grid  image = {service6} text={t('text6')} titre={t('service6')}></Grid></div>
            <div id="service7" className="animate-fade-up animate-duration-[3500ms]"><Grid  image = {service7} text={t('text7')} titre={t('service7')}></Grid></div>
            <div id="service9" className="animate-fade-up animate-duration-[3500ms]"><Grid  image = {service9} text={t('text10')} titre={t('service8')}></Grid></div>
            <div id="service10" className="animate-fade-up animate-duration-[3500ms]"><Grid image = {service10} text={t('text99')} titre={t('service9')}></Grid></div>
            <div id="service8" className="animate-fade-up animate-duration-[3500ms]"><Grid  image = {service8} text={t('text8')} titre={t('service10')}></Grid></div>
            
            <GridFooter></GridFooter>
            <Footer></Footer>  
        </div>
    )
}