import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { NavLink } from 'react-router-dom';
import { useTranslation } from "react-i18next"

export default function GridFooter(){

    const { t} = useTranslation(); 

    return ( 
        <div className=" md:p-10 flex flex-col items-center bg-white   md:flex-row ">
            <div  className="flex flex-col justify-between p-3 leading-normal">
                <h5  className="mb-2 text-4xl font-bold tracking-tight  text-green-500">
                    {t('NonTrouver')}
                </h5>
                <div className="bg-green-500 rounded-xl p-5 text-white">
                    <div className="flex justify-between">
                        <SupportAgentIcon style={{fontSize:80}} className="text-white" ></SupportAgentIcon>
                        <NavLink to="/contact#form" className='animate-bounce text-xl border-solid border-2 border-white p-2 h-fit hover:text-black hover:bg-white' >{t('contacter')}</NavLink>
                    </div>
                    <div className="text-5xl font-bold">
                        <p >{t('client')}</p>
                    </div>
                    <div className="text-xl pt-5">
                        <p className="text-justify">
                            {t('rendezvous')}
                        </p>      
                    </div>             
                </div>
            </div>
        </div> 
        )
}