
import Slider from "react-slick";


import img1 from '../../image/c1.jpg'
 import img2 from '../../image/c2.jpg'
 import img3 from '../../image/c3.jpg'
 import img4 from '../../image/c4.jpg'
 import img5 from '../../image/c5.jpg'
 import img6 from '../../image/c6.jpg'
 import img7 from '../../image/c7.jpg'
 import img8 from '../../image/c8.jpg'
 import img9 from '../../image/c9.jpg'
 import img10 from '../../image/c10.jpg'



function AutoPlay() {
  const settings = {
   
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 8000,
    adaptiveHeight: true,
    centerMode: true,
    variableWidth: true,
    autoplaySpeed: 1500,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          speed: 8000,
          slidesToScroll: 3,
          adaptiveHeight: true,
          infinite: true,
          autoplaySpeed: 1500,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          speed: 8000,
          initialSlide: 2,
          adaptiveHeight: true,
          autoplaySpeed: 1500,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          speed: 8000,
          adaptiveHeight: true,
          slidesToScroll: 1,
          autoplaySpeed: 1500,
        }
      }
    ]

  };
 
 
  return (
    <div>
             <div className="slider-container" >
            <Slider {...settings} >
                
                <div>
                  <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 150,marginLeft:10,marginRight:10, height:120, backgroundImage: `url(${img1})`, backgroundPosition: 'center',backgroundSize: 'contain',backgroundRepeat: 'no-repeat', borderRadius:10,backgroundColor: "white"}} ></div>
                </div>
                
                <div >
                  <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 150,marginLeft:10,marginRight:10, height:120, backgroundImage: `url(${img2})`, backgroundPosition: 'center',backgroundSize: 'contain',backgroundRepeat: 'no-repeat', borderRadius:10,backgroundColor: "white"}} ></div>
                </div>
                <div >
                  <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 150,marginLeft:10,marginRight:10, height:120, backgroundImage: `url(${img3})`, backgroundPosition: 'center',backgroundSize: 'contain',backgroundRepeat: 'no-repeat', borderRadius:10,backgroundColor: "white"}} ></div>
                </div>
                <div >
                  <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 150,backgroundColor:'white',marginLeft:10,marginRight:10, height:120, backgroundImage: `url(${img7})`, backgroundPosition: 'center',backgroundSize: 'contain',backgroundRepeat: 'no-repeat', borderRadius:10}} ></div>
                </div>
                <div >
                  <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 150,marginLeft:10,marginRight:10, height:120, backgroundImage: `url(${img4})`, backgroundPosition: 'center',backgroundSize: 'contain',backgroundRepeat: 'no-repeat', borderRadius:10,backgroundColor: "white"}} ></div>
                </div>
                <div >
                  <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 150,marginLeft:10,marginRight:10, height:120, backgroundImage: `url(${img5})`, backgroundPosition: 'center',backgroundSize: 'contain',backgroundRepeat: 'no-repeat', borderRadius:10,backgroundColor: "white"}} ></div>
                </div>
                <div >
                  <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 150,marginLeft:10,marginRight:10, height:120, backgroundImage: `url(${img6})`, backgroundPosition: 'center',backgroundSize: 'contain',backgroundRepeat: 'no-repeat', borderRadius:10,backgroundColor: "white"}} ></div>
                </div>
                <div >
                  <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 150,marginLeft:10,marginRight:10, height:120, backgroundImage: `url(${img8})`, backgroundPosition: 'center',backgroundSize: 'contain',backgroundRepeat: 'no-repeat', borderRadius:10,backgroundColor: "white"}} ></div>
                </div>
                <div >
                  <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 150,marginLeft:10,marginRight:10, height:120, backgroundImage: `url(${img9})`, backgroundPosition: 'center',backgroundSize: 'contain',backgroundRepeat: 'no-repeat', borderRadius:10,backgroundColor: "white"}} ></div>
                </div>
                <div >
                  <div style={{boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', width: 150,marginLeft:10,marginRight:10, height:120, backgroundImage: `url(${img10})`, backgroundPosition: 'center',backgroundSize: 'contain',backgroundRepeat: 'no-repeat', borderRadius:10,backgroundColor: "white"}} ></div>
                </div>
            </Slider>
        </div>     
</div> );
}

export default AutoPlay;
