import Header from "../component/header/Header"
import Footer from "../component/footer/Footer"
import GridFooter from "../component/grid/GridFooter"
import Carousel from "../component/grid/Carousel"
import projet from '../image/projet.png'
import img1 from '../image/p1.jpg'
import img2 from '../image/p2.jpg'
import img3 from '../image/p3.jpg'
import img4 from '../image/p4.jpg'
import img5 from '../image/p5.jpg'
import img6 from '../image/p6.jpg'
import img7 from '../image/p7.jpg'
import img8 from '../image/p8.jpg'
import img9 from '../image/p9.jpg'
import img10 from '../image/p10.jpg'
import img11 from '../image/p11.jpg'
import img12 from '../image/p12.jpg'
import img13 from '../image/p13.jpg'
import img14 from '../image/p14.jpg'
import img15 from '../image/p15.jpg'
import img16 from '../image/p16.jpg'
import img17 from '../image/p17.jpg'
import img18 from '../image/p18.jpg'
import img19 from '../image/p19.jpg'
import img20 from '../image/p20.jpg'
import img21 from '../image/p21.jpg'
import img22 from '../image/p22.jpg'
import img23 from '../image/p23.jpg'
import img24 from '../image/p24.jpg'
import img25 from '../image/p25.jpg'
import img27 from '../image/p27.jpg'
import img29 from '../image/p29.jpg'
import img28 from '../image/p28.jpg'
import img30 from '../image/p30.jpg'
import img32 from '../image/p32.jpg'
import { useEffect } from "react"
import { t } from "i18next"
export default function Projet(){
    
    
    let slides1 = [
        img1,img2,img3,img4,img5
      ];
    let slides2 = [
        img6,img7,img8,img9,img10
      ];
    let slides3 = [
        img11,img12,img13,img14,img15
      ];
    let slides4 = [
        img16,img17,img18,img19,img20
    ];
    let slides5 = [
        img20,img21,img22,img23,img24,img25
    ]

    let slides6 = [
        img27,img28,img29,img30,img32
    ]
    useEffect(() => {
        const id = window.location.hash.substr(1);
        if (id) {
            setTimeout(() => {
                window.scrollTo({top: 700, behavior: 'smooth'});
            }, 100); 
        }else{
            window.scrollTo(0,0); // Scroll to the new position (smooth scrolling not included)
        }
    }, []);
    return(
        <div> 
            <Header image={projet} titre1={t('nos')} titre2={t('realisation')} menu="projet"></Header>



            <div className="m-5 sm:m-10 flex flex-col items-center bg-white border border-gray-200  md:flex-row  hover:bg-gray-100">
                <div className="object-cover sm:w-[500px] rounded-t-lg h-50 md:h-auto  md:rounded-lg md:rounded-s-lg">
                    <Carousel slides={slides1} />
                </div>
                <div  className="flex flex-col sm:w-1/2 justify-between p-4 leading-normal">
                    <h5  className="mb-10 text-2xl md:text-5xl font-bold tracking-tight   text-green-500 "> 
                        {t("projetTitle1")}
                    </h5>
                    <p  className="mb-3 text-2xl font-normal text-gray-700  ">
                        {t("clients")} : Nouvelle Gabon Mining
                    </p>
                    <p  className="mb-3 text-2xl font-normal text-gray-700  ">
                        {t("projets")} : {t('porjets1')}   
                    </p>
                    <p  className="mb-3 text-2xl font-normal text-gray-700  ">
                        {t("objet")} : {t('objet1')}
                    </p>
                    <p  className="mb-3 text-2xl font-normal text-gray-700  ">
                        {t("date")} : {t("date1")}
                    </p>
                    
                </div>
            </div> 

            <div className="m-5 sm:m-10 flex flex-col items-center bg-white border border-gray-200  md:flex-row  hover:bg-gray-100 ">
                <div className="object-cover sm:w-[500px] rounded-t-lg h-50 md:h-auto  md:rounded-lg md:rounded-s-lg">
                    <Carousel slides={slides3} />
                </div>
                <div  className="flex flex-col justify-between p-4 leading-normal">
                    <h5  className="mb-10 text-2xl md:text-5xl font-bold tracking-tight   text-green-500  "> 
                        {t("projetTitle2")}
                        
                    </h5>
                    <p  className="mb-3 text-xl font-normal text-gray-700  ">
                        {t("clients")} : C2020 COMILOG
                    </p>
                    <p  className="mb-3 text-xl font-normal text-gray-700  ">
                        {t("projets")} : {t('porjets2')}  
                    </p>
                    <p  className="mb-3 text-xl font-normal text-gray-700 ">
                        {t("objet")} : {t('objet2')}
                    </p>
                    <p  className="mb-3 text-xl font-normal text-gray-700">
                        {t("date")} : {t("date2")}
                    </p>
                </div>
            </div> 
            
            <div className="m-5 sm:m-10 flex flex-col items-center bg-white border border-gray-200  md:flex-row  hover:bg-gray-100 ">
                <div className="object-cover sm:w-[500px] rounded-t-lg h-50 md:h-auto  md:rounded-lg md:rounded-s-lg">
                    <Carousel slides={slides4} />
                </div>
                <div  className="flex flex-col justify-between p-4 leading-normal">
                    <h5  className="mb-10 text-2xl md:text-5xl font-bold tracking-tight   text-green-500  "> 
                        {t("projetTitle3")}
                       
                    </h5>
                    <p  className="mb-3 text-xl font-normal text-gray-700  ">
                       {t("clients")} : DIEP COMILOG
                    </p>
                    <p  className="mb-3 text-xl font-normal text-gray-700  ">
                        {t("projets")} : {t("projets3")}
                    </p>
                    <p  className="mb-3 text-xl font-normal text-gray-700 ">
                        {t("objet")} : {t("objet3")} 
                    </p>
                    <p  className="mb-3 text-xl font-normal text-gray-700">
                        {t("date")} : {t("date3")}
                    </p>
                </div>
            </div> 

            <div className="m-5 sm:m-10 flex flex-col items-center bg-white border border-gray-200  md:flex-row  hover:bg-gray-100 ">
                <div className="object-cover sm:w-[500px] rounded-t-lg h-50 md:h-auto  md:rounded-lg md:rounded-s-lg">
                    <Carousel slides={slides5} />
                </div>
                <div  className="flex flex-col justify-between p-4 leading-normal">
                    <h5  className="mb-10 text-2xl md:text-5xl font-bold tracking-tight   text-green-500  "> 
                        {t("projetTitle4")}
                    </h5>
                    <p  className="mb-3 text-xl font-normal text-gray-700  ">
                       {t("clients")} :  {t("client4")}
                    </p>
                    <p  className="mb-3 text-xl font-normal text-gray-700  ">
                        {t("projets")} : {t("projets4")}  
                    </p>
                    <p  className="mb-3 text-xl font-normal text-gray-700 ">
                        {t("objet")} : {t("objet4")}
                    </p>
                    <p  className="mb-3 text-xl font-normal text-gray-700">
                        {t("date")} : {t("date4")}
                    </p>
                </div>
            </div>

            <div className="m-5 sm:m-10 flex flex-col items-center bg-white border border-gray-200  md:flex-row  hover:bg-gray-100 ">
                <div className="object-cover sm:w-[575px] rounded-t-lg h-50 md:h-auto  md:rounded-lg md:rounded-s-lg">
                    <Carousel slides={slides6} />
                </div>
                <div  className="flex flex-col justify-between p-4 leading-normal">
                    <h5  className="mb-10 text-2xl md:text-5xl font-bold tracking-tight   text-green-500  "> 
                        {t("projetTitle5")}
                    </h5>
                    <p  className="mb-3 text-xl font-normal text-gray-700  ">
                       {t("clients")} : C2020 COMILOG
                    </p>
                    <p  className="mb-3 text-xl font-normal text-gray-700  ">
                        {t("projets")} :  {t("projets5")}
                    </p>
                    <p  className="mb-3 text-xl font-normal text-gray-700 ">
                        {t("objet")} : {t("objet5")}
                    </p>
                    <p  className="mb-3 text-xl font-normal text-gray-700">
                        {t("date")} : {t("date5")}
                    </p>
                </div>
            </div> 

            
            <div className=" m-5 sm:m-10 flex flex-col items-center bg-white border border-gray-200  md:flex-row  hover:bg-gray-100 ">
                <div className="object-cover sm:w-[600px]  rounded-t-lg h-50 md:h-auto  md:rounded-lg md:rounded-s-lg">
                    <Carousel slides={slides2} />
                </div>
                <div  className="flex flex-col justify-between p-4 leading-normal">
                    <h5  className="mb-10 text-2xl md:text-5xl font-bold tracking-tight text-green-500  "> 
                        {t("projetTitle6")}
                    </h5>
                    <p  className="mb-3 text-xl font-normal text-gray-700  ">
                       {t("clients")} : Nouvelle Gabon Mining
                    </p>
                    <p  className="mb-3 text-xl font-normal text-gray-700  ">
                        {t("projets")} : {t('projets6')}    
                    </p>
                    <p  className="mb-3 text-xl font-normal text-gray-700 ">
                        {t("objet")} : {t('objet6')}
                    </p>
                    <p  className="mb-3 text-xl font-normal text-gray-700">
                        {t("date")} : {t("date6")}
                    </p>
                </div>
            </div> 



            
                    
            <GridFooter></GridFooter>
            <Footer></Footer>  
        </div>
    )
}