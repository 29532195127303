import React, { useState } from "react";
import { useRef } from "react";
import { MapContainer, TileLayer,Marker ,Tooltip } from "react-leaflet";
import $ from 'jquery';
import {CircularProgress,Alert} from "@mui/material/";

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import con from "../../image/broche-de-localisation.png"
import { t } from "i18next";

export default function Form () {
    const [name, setName] = useState('')
    const [namePrise, setNamePrise] = useState('')
    const [email,setEmail] = useState('')
    const [objet,setObjet] = useState('')
    const [message,setMessage] = useState("")
    const [role,setRole] = useState('')
    const [your,setYour] = useState('')
    const [spinner,setSpinner] = useState(false)


    const handleObjet = (e)=>{
      setObjet( e.target.value)
     }
    const handleRole = (e)=>{
     setRole( e.target.value)
    }
    const handleYour = (e)=>{
      setYour( e.target.value)
     }

    const mapRef = useRef(null);
    const latitude = -1.512934;
    const longitude = 13.201363;

    const myIcon = L.icon({
        iconUrl: con,
        iconSize: [40, 40],
        iconAnchor: [12, 41],
        popupAnchor: [0, -41]
    });

    const handleSubmit = (e) => {
      // Traiter les données du formulaire
      e.preventDefault();
      setSpinner(true)
  
      const fData = {name,namePrise,objet, email, message,role,your}
      console.log(fData);
        $.ajax({
          url : "https://" + window.location.hostname +"/httpmail/index.php",
          method : "POST",
          data : fData,
          dataType:"json",
          error : function () {
            $('#result').css('display','block')
          },
          success : function(){
              setSpinner(false)
              setName("")
              setNamePrise("")
              setObjet("")
              setRole("")
              setYour("")
              setEmail("")
              setMessage("")
              $('#resultat').css('display','block')
          },
        }) 
        
    };

  return (
    <>
      <section  className="relative z-10 overflow-hidden bg-white p-10 lg:py-[120px]"  >
        <div className="container">
          <div className="-mx-4 flex flex-wrap-reverse ">
            
            <div className="w-full md:px-5 lg:w-1/2 xl:w-1/2 h-1/2" >
              <div className="relative rounded-lg bg-white p-5 shadow-lg sm:p-12">
                <form onSubmit={handleSubmit} method="POST"  >

                  <div className="mb-6">
                    <label htmlFor="subject" className="text-lg font-normal">{t('souhaite')}</label>
                    <select data-drupal-selector="edit-subject" id="edit-subject" name="objet" value={objet} onChange={handleObjet} className="w-full rounded border border-stroke px-[14px] py-3 text-base text-body-color outline-none focus:border-primary" required="required" aria-required="true">
                      <option value="" selected="selected">-{t('selectionner')}-</option>
                      <option value="demande-informations">{t('demande information')}</option>
                      <option value="demande-de-devis">{t('demande devis')}</option>
                      <option value="contact-presse-webmaster">{t('contact presse')}</option>
                      <option value="candidature-spontanee">{t('candidature spontanée')}</option>
                    </select>
                  </div>

                  <div className="mb-6">
                    <label htmlFor="subject_of" className="text-lg font-normal">{t('concernant')}</label>
                    <select data-drupal-selector="edit-subject-of" id="edit-subject-of" name="role" value={role} onChange={handleRole} className="w-full rounded border border-stroke px-[14px] py-3 text-base text-body-color outline-none focus:border-primary" data-drupal-states="{&quot;visible&quot;:[{&quot;.webform-submission-contact-paragraph-466-add-form :input[name=\u0022subject\u0022]&quot;:{&quot;value&quot;:&quot;demande-informations&quot;}},&quot;xor&quot;,{&quot;.webform-submission-contact-paragraph-466-add-form :input[name=\u0022subject\u0022]&quot;:{&quot;value&quot;:&quot;demande-de-devis&quot;}}],&quot;invisible&quot;:[{&quot;.webform-submission-contact-paragraph-466-add-form :input[name=\u0022subject\u0022]&quot;:{&quot;value&quot;:&quot;candidature-spontanee&quot;}},&quot;xor&quot;,{&quot;.webform-submission-contact-paragraph-466-add-form :input[name=\u0022subject\u0022]&quot;:{&quot;value&quot;:&quot;contact-presse-webmaster&quot;}}],&quot;required&quot;:[{&quot;.webform-submission-contact-paragraph-466-add-form :input[name=\u0022subject\u0022]&quot;:{&quot;value&quot;:&quot;demande-informations&quot;}},&quot;xor&quot;,{&quot;.webform-submission-contact-paragraph-466-add-form :input[name=\u0022subject\u0022]&quot;:{&quot;value&quot;:&quot;demande-de-devis&quot;}}]}">
                      <option value="" selected="selected">- {t('selectionner')} -</option>
                      <option value="Analyses et essais sur matériau">{t('analyse')}</option>
                      <option value="Étude de sol">{t('etude sol')}</option>
                      <option value="Étude et prévention des risques naturels">{t('risque')}</option>
                      <option value="Ingénierie géotechnique">{t('ingenierie')}</option>
                      <option value="Inspection, diagnostic, surveillance des ouvrages">{t('diagnostique')}</option>
                      <option value="Forage et adduction d'eau ">{t('forage')}</option>
                      <option value="Autre">{t('autre')}</option>
                    </select>
                  </div>

                  <div className="mb-6">
              <label htmlFor="your" className="text-lg font-normal" >{t('je suis')}</label>
                <select data-drupal-selector="edit-your" id="edit-your" name="your" value={your} onChange={handleYour} className="w-full rounded border border-stroke px-[14px] py-3 text-base text-body-color outline-none focus:border-primary" data-drupal-states="{&quot;visible&quot;:[{&quot;.webform-submission-contact-paragraph-466-add-form :input[name=\u0022subject\u0022]&quot;:{&quot;value&quot;:&quot;demande-informations&quot;}},&quot;xor&quot;,{&quot;.webform-submission-contact-paragraph-466-add-form :input[name=\u0022subject\u0022]&quot;:{&quot;value&quot;:&quot;demande-de-devis&quot;}}],&quot;invisible&quot;:[{&quot;.webform-submission-contact-paragraph-466-add-form :input[name=\u0022subject\u0022]&quot;:{&quot;value&quot;:&quot;candidature-spontanee&quot;}},&quot;xor&quot;,{&quot;.webform-submission-contact-paragraph-466-add-form :input[name=\u0022subject\u0022]&quot;:{&quot;value&quot;:&quot;contact-presse-webmaster&quot;}}],&quot;required&quot;:[{&quot;.webform-submission-contact-paragraph-466-add-form :input[name=\u0022subject\u0022]&quot;:{&quot;value&quot;:&quot;demande-informations&quot;}},&quot;xor&quot;,{&quot;.webform-submission-contact-paragraph-466-add-form :input[name=\u0022subject\u0022]&quot;:{&quot;value&quot;:&quot;demande-de-devis&quot;}}]}">
                  <option value="" selected="selected">- {t('selectionner')} -</option>
                  <option value="Acteur de l'immobilier">{t('acteur')}</option>
                  <option value="Entreprise de travaux">{t('travaux')}</option>
                  <option value="Expert judiciaire / Assurance">{t('judiciaire')}</option>
                  <option value="Fabriquant de matériaux">{t('materiaux')}</option>
                  <option value="Maître d'oeuvre">{t('oeuvre')}</option>
                  <option value="Maître d'ouvrage">{t('ouvrage')}</option>
                  <option value="Particulier">{t('particulier')}</option>
                  <option value="Autre">{t('autre')}</option>
                </select>    
              </div>

                    <div className="mb-6">
                        <label className="text-lg font-normal">{t('nom')}</label>
                        <input required type="text" value={name} onChange={(e)=>{setName(e.target.value)}} className="w-full rounded border border-stroke px-[14px] py-3 text-base text-body-color outline-none focus:border-primary"/>
                    </div>

                    <div className="mb-6">
                        <label className=" font-normal text-lg">{t('entreprise')}</label>
                        <input required type="text" value={namePrise} onChange={(e)=>{setNamePrise(e.target.value) }}  className="w-full rounded border border-stroke px-[14px] py-3 text-base text-body-color outline-none focus:border-primary    "/>
                    </div>

                    <div className="mb-6">
                        <label className=" font-normal text-lg">{t('email')}</label>
                        <input required type="email" value={email} onChange={(e)=>{setEmail(e.target.value) }} className="w-full rounded border border-stroke px-[14px] py-3 text-base text-body-color outline-none focus:border-primary"/>
                    </div>

                    <div className="mb-6">
                        <label className=" font-normal text-lg">{t('message')}</label>
                        <textarea required rows={4} name={10} value={message} onChange={(e)=>{setMessage(e.target.value) }} className="w-full resize-none rounded border border-stroke px-[14px] py-3 text-base text-body-color outline-none focus:border-primary    "/>
                    </div>

                    <div>
                      <Alert id ="resultat" style={{display:'none'}}  severity="success">{t('true')}</Alert>
                      <Alert id ="result" style={{display:'none'}} severity="error">{t('false')}</Alert>
                      {spinner === true ? <p style={{textAlign:"center" }} ><CircularProgress style={{color:"#00e676"}} /> </p> : <button type="submit" className="w-full bg-green-500 rounded border border-primary bg-primary p-3 text-white transition hover:bg-opacity-90">{t('envoyer')}</button>}                       
                    </div>

                </form>
                <div>
                  <span className="absolute -right-9 -top-10 z-[-1]">
                    <svg
                      width={100}
                      height={100}
                      viewBox="0 0 100 100"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 100C0 44.7715 0 0 0 0C55.2285 0 100 44.7715 100 100C100 100 100 100 0 100Z"
                        fill="#22c55e"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>

            <div className="w-full md:px-5 lg:w-1/2 xl:w-1/2 h-1/2">
                
                <MapContainer center={[latitude, longitude]} zoom={15} ref={mapRef}  className="lg:h-[940px] h-[300px]">
                        <TileLayer
                        url={`https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&hl=en&gl=US&${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
                        subdomains={["mt0", "mt1", "mt2", "mt3"]}

                        />
                        {/* Additional map layers or components can be added here */}
                    
                            <Marker position={[latitude, longitude]} icon={myIcon} >
                                    <Tooltip  permanent>
                                        Bureau SCIENCES SOLS
                                    </Tooltip >
                            </Marker>                           
                    </MapContainer>
            </div>

          </div>

        </div>
      </section>

      
    </>
  );
};
