import React from "react"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fade from '@mui/material/Fade';
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";


export default function Grid({image,text,titre}) {

    const {t} = useTranslation();
    

    return ( 
    <div  className="m-5 sm:m-10 flex flex-col items-center bg-white border border-gray-200  md:flex-row  hover:bg-gray-100">
        <img   style={{width:400}} className="object-cover w-full rounded-t-lg h-50 md:h-auto md:w-48 md:rounded-lg md:rounded-s-lg" src={image} alt=""  />
        <div  className="flex flex-col justify-between p-4 leading-normal">
            <h5  className="mb-2 text-3xl font-bold tracking-tight   text-green-500 ">{titre}</h5>
            <p  dangerouslySetInnerHTML={{ __html:text}}  className="mb-3 font-normal text-xl text-gray-700 text-justify">
                
            </p>
             
            {titre === t('service8') ? <Expend></Expend> : ''}
            
            <div className="text-end mt-8 animate-bounce animate-infinite">
            <NavLink to='/contact#form' className=' text-xl border-solid border-2 bg-green-500 text-white p-2 h-fit hover:text-black hover:bg-white' >{t('plus')}</NavLink>
            </div>
           
        </div>
    </div> 
    )
}



const Expend = ()=>{
    
    const [expanded, setExpanded] = React.useState(false);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const {t} = useTranslation()

  return (
        <div >
        <Accordion
            style={{boxShadow:'none',backgroundColor:'transparent'}}
            expanded={expanded}
            onChange={handleExpansion}
            slots={{ transition: Fade }}
            slotProps={{ transition: { timeout: 400 } }}
            sx={{
            '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
            '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' },
            }}
        >
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            >
            <p className="text-green-500 text-2xl">{t('essai')}</p>
            </AccordionSummary>
            <AccordionDetails>
            <p  className="mb-3 font-normal text-xl text-gray-700 text-justify">
                {t('essai identification')}
            </p>
            <p  className="mb-3 font-normal text-xl text-gray-700 text-justify">
               {t('resultat')}
            </p>
            
            </AccordionDetails>
        </Accordion>

        <Accordion style={{boxShadow:'none',backgroundColor:'transparent'}}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
            >
            <p className="text-green-500 text-2xl">{t('essai1')}</p>
            </AccordionSummary>
            <AccordionDetails>
            <p className="mb-3 font-normal text-xl text-gray-700 text-justify">
                {t('essai material')}
            </p>
            </AccordionDetails>
        </Accordion>

        <Accordion style={{boxShadow:'none',backgroundColor:'transparent'}}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
            >
            <p className="text-green-500 text-2xl">{t('essai2')}</p>
            </AccordionSummary>
            <AccordionDetails>
            <p className="mb-3 font-normal text-xl text-gray-700 text-justify">
                {t('etude')}
            </p>
            <p className="mb-3 font-normal text-xl text-gray-700 text-justify">
                {t('contexte')}  
            </p>
            </AccordionDetails>
        </Accordion>

        <Accordion style={{boxShadow:'none',backgroundColor:'transparent'}}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
            >
            <p className="text-green-500 text-2xl">{t('essai3')}</p>
            </AccordionSummary>
            <AccordionDetails>
            <p className="mb-3 font-normal text-xl text-gray-700 text-justify">
                {t('essai compression')}
            </p>
            </AccordionDetails>
        </Accordion>

        <Accordion style={{boxShadow:'none',backgroundColor:'transparent'}}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
            >
            <p className="text-green-500 text-2xl">{t('essai4')}</p>
            </AccordionSummary>
            <AccordionDetails>
            <p className="mb-3 font-normal text-xl text-gray-700 text-justify">
               {t('resistance')}
            </p>
            <p className="mb-3 font-normal text-xl text-gray-700 text-justify">
               {t("propriete")}
            </p>
            </AccordionDetails>
        </Accordion>
        </div>
    )
}
