
import './App.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./pages/About";
import Home from "./pages/Home";
import Service from "./pages/Service";
import Contact from "./pages/Contact";
import Projet from './pages/Projet';


export default function App() {

  return (
    <div style={{ overflowX:"clip"}}>
        <BrowserRouter>
          <Routes>
              <Route  path="/" element={<Home/>}></Route>
              <Route  path="accueil" element={<Home/>}></Route>
              <Route path="Nos réalisations" element={<Projet />} />
              <Route path="service" element={<Service />} />
              <Route path="contact" element={<Contact />} />
              <Route path="Apropos" element={<About />} />
          </Routes>
        </BrowserRouter>
    </div>
  );
    
    
}

