import logo from "../../image/logo.png";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LanguageIcon from '@mui/icons-material/Language';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next"

import React from "react";
  const dateActuelle = new Date();
  const annee = dateActuelle.getFullYear();
  
const Footer = () => {
  const { t } = useTranslation(); 
  return (
    <>
      <footer className="relative z-10 text-white bg-green-500 p-10 lg:pb-20 lg:pt-[120px]">
        <div className='container' >
          <div className=" lg:flex lg:justify-around">
            <div className="w-full px-4 sm:w-2/3 lg:w-3/12">
              <div className="mb-10 w-full">
                <NavLink to="/" className="inline-block max-w-[160px] bg-white w-full rounded ">
                  <img
                    src={logo}
                    alt="logo"
                    className="max-w-full "
                  />
                  
                </NavLink>
                <p className="text-lg text-body-color ">
                    {t('slogon1')} <br/>
                    {t('slogon2')}
                </p>
                <a href="https://www.linkedin.com/in/sciences-sols-48286111b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"> <LinkedInIcon fontSize="large"></LinkedInIcon></a>
                <a href="https://www.facebook.com/etudesol?mibextid=LQQJ4d"><FacebookIcon fontSize="large"></FacebookIcon></a>
                
              </div>
            </div>

            <LinkGroup header={t('rapide')}>

              <Link link="/accueil" label={t('accueil')} />
              <Link link="/service" label={t('service')} />
              <Link link="/Nos réalisations" label={t('projet')} />
              <Link link="/apropos" label={t('apropos')} />
              <Link link="/contact" label={t('contacter')} />

            </LinkGroup>
            
            <LinkGroup header="Contact">
            <div className="">
                <ol className="space-y-3 ">

                    <li className="text-body-color flex text-lg w-screen">
                        <LocationOnIcon fontSize="large" ></LocationOnIcon>
                        <p className="pl-2">BP : 810 Libreville (Gabon)</p>
                    </li>

                    <li className="text-body-color flex text-lg w-screen">
                        <PhoneIcon fontSize="large"></PhoneIcon>
                        <p className="pl-2">(+241) 060 42 05 40 / 060 42 05 31</p>
                    </li>

                    <li className="text-body-color   flex text-lg w-screen">
                        <EmailIcon fontSize="large"></EmailIcon>
                        <p className="pl-2">info@sciencesols.com</p>
                    </li>

                    <li className="text-body-color   flex text-lg w-screen">
                       <LanguageIcon fontSize="large" ></LanguageIcon>
                       <p  className="pl-2">sciencesols.com</p>
                    </li>
       
                </ol>
            </div>
            </LinkGroup>
          </div>
          <hr className="my-6 border-gray-200 sm:mx-auto lg:my-8" />
          <span className="block text-sm text-white sm:text-center">© {annee} Sciences sols.</span>
        </div>
      </footer>
    </>
  );
};

export default Footer;

const LinkGroup = ({ children, header }) => {
  return (
    <>
      <div className="w-full px-4 sm:w-1/2 lg:w-2/12">
        <div className="mb-10 w-full">
          <h1 className="mb-9 text-2xl font-semibold ">
            {header}
          </h1>
          <ul className="space-y-3">{children}</ul>
        </div>
      </div>
    </>
  );
};

const Link = ({ link, label }) => {
  return (
    <li>
      <NavLink
        to={link}
        className="inline-block text-lg leading-loose text-body-color hover:text-primary  "
      >
        {label}
      </NavLink>
    </li>
  );
};
