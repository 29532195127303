
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";


export default function Grids ({titre,image,contact,lien}) {

  
  return (
    <>
    <NavLink to={lien}  >
      <div style={{ backgroundImage: `url(${image})`, backgroundPosition:"center" ,backgroundSize:"cover", position:'relative'}} className=" h-72 ">
          {contact === '/contact' ? <Buton></Buton> : ""}
          <div  style={{backgroundColor:'#09930569'}} className="absolute bottom-0 text-center w-full h-1/5">
            <p  className="text-white text-xl ">
              {titre}
            </p>
            {contact === '/contact' ? <Second></Second> : ""}
          </div>

        </div>  
    </NavLink>
      
    </>
  );
};

const Buton =() => {
   
  const { t} = useTranslation();
  return <div   className="absolute top-24 text-center w-full h-1/5">
              <NavLink to='/contact#form' style={{backgroundColor:'#09930569'}} className='text-xl border-solid border-2 text-white border-white p-2 h-fit hover:text-black hover:bg-white' >{t('contacter')}</NavLink>
        </div> 
}
const Second =() =>{
   
  const { t} = useTranslation();
  return <>
          <p className="text-white text-xl">
            {t('serviceAutres')}
          </p>
  </>
}

