import { useState,React } from 'react';
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import logo from '../../image/logo.png'
import image1 from '../../image/about.png'
import image2 from '../../image/about2.png'
import image3 from '../../image/accueil.jpg'
import Slider from '../slider/Slider';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from "react-country-flag"
import $ from 'jquery';


const Hero = ({image,titre1,titre2,titre3,menu}) => {
  const styles = {
    backgroundColor:'white',
    color:'#22c55e',
    borderRadius:'5px',
    height:"40px",
    padding:"5px"
  }
  const [vue,setVue] = useState('false')
  const handleOnclik = ()=>{
      vue === 'true' ? setVue('false') : setVue('true')
  }
  const responsive = useMediaQuery('(max-width:1158px)');

  $(window).scroll(function (e) {
    // A chaque fois que l'utilisateur va scroller (descendre la page)
    var y = $(this).scrollTop(); // On récupérer la valeur du scroll vertical
 
    //si cette valeur > à 200 on ajouter la class
    if (y >= 200) {
      $('#nav').addClass('fixed');
      
    } else {
      // sinon, on l'enlève
    $('#nav').removeClass('fixed');
    $('#navbar').css('margin-top','0px');
    }
  });
    const { t } = useTranslation();

    return (
      <>
         <div id='haut'ù></div>
         <nav id='nav' className=" p-2 sm:-mt-50 bg-green-500 border-gray-200 fixed z-20 w-full text-white">
            <div className="flex  justify-between items-center">
                <NavLink to="/" className=" justify-center flex items-center w-[100px] sm:w-auto  md:w-[120px] space-x-3 rtl:space-x-reverse bg-white rounded-3xl p-3">
                    <img src={logo} alt="sciencesol Logo" />
                </NavLink>

                <div className={responsive ? 'block flex ' : 'hidden'}  >
                    <Langue></Langue>
                    <button onClick={handleOnclik}   type="button" className='p-2' >
                      <a href='#haut'>
                        <span className="sr-only">Open main menu</span>
                          {vue === 'true' ? <CloseIcon fontSize='large'></CloseIcon> : <svg  className="w-8 h-8"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
                          </svg>}
                      </a>
                    </button>
                </div>
                <div  className={responsive ? 'hidden' : 'block'} >
                    <ul  className="flex justify-between">
                        <li className='p-5'>
                            <NavLink to="/"  style={menu === "accueil" ? styles: null} className=" font-bold block py-2 px-3 text-white rounded text-2xl   md:hover:bg-transparent md:hover:text-blue-700 md:p-0  " >{t('accueil')}</NavLink>
                        </li>
                        <li className='p-5'>
                            <NavLink to="/service" style={menu === "service" ? styles: null}   className="  font-bold block py-2 px-3 text-white rounded text-2xl   md:hover:bg-transparent md:hover:text-blue-700 md:p-0  ">{t('service')}</NavLink>
                        </li>
                        <li className='p-5'>
                            <NavLink to="/Nos réalisations"  style={menu === "projet" ? styles: null}  className="  font-bold block py-2 px-3 text-white rounded text-2xl   md:hover:bg-transparent md:hover:text-blue-700 md:p-0  ">{t('projet')}</NavLink>
                        </li>
                        <li className='p-5'> 
                            <NavLink to="/apropos"   style={menu === "apropos" ? styles: null} className="  font-bold block py-2 px-3 text-white rounded text-2xl   md:hover:bg-transparent md:hover:text-blue-700 md:p-0  ">{t('apropos')}</NavLink>
                        </li>
                        <li className='p-5'>
                            <NavLink to="/contact"  style={menu === "contact" ? styles: null} className="nav-a  font-bold  block py-2 px-3 text-white rounded text-2xl   md:hover:bg-transparent md:hover:text-blue-700 md:p-0  ">{t('contacter')}</NavLink>
                        </li>
                        <li className='p-4'>
                          <Langue></Langue>
                        </li>                       
                    </ul>
                </div>
            </div>
        </nav>

      <div className='p-10 bg-green-500 text-white '>            
            <div id='menu' className={vue === 'false' ? 'hidden' : 'block -mx-8'} >
              <ul  className="flex flex-col border rounded-xl ">
                  <li className='p-3'>
                      <NavLink to="/"  style={menu === "accueil" ? styles: null} className=" font-bold block py-2 px-3 text-white rounded text-2xl md:hover:bg-transparent md:hover:text-blue-700 md:p-0  " >{t('accueil')}</NavLink>
                  </li>
                  <li className='p-3'>
                      <NavLink to="/service" style={menu === "service" ? styles: null}   className="  font-bold block py-2 px-3 text-white rounded text-2xl md:hover:bg-transparent md:hover:text-blue-700 md:p-0  ">{t('service')}</NavLink>
                  </li>
                  <li className='p-3'>
                      <NavLink to="/Nos réalisations"  style={menu === "projet" ? styles: null}  className="  font-bold block py-2 px-3 text-white rounded text-2xl   md:hover:bg-transparent md:hover:text-blue-700 md:p-0  ">{t('projet')} </NavLink>
                  </li>
                  <li className='p-3'> 
                      <NavLink to="/apropos"   style={menu === "apropos" ? styles: null} className="  font-bold block py-2 px-3 text-white rounded text-2xl md:hover:bg-transparent md:hover:text-blue-700 md:p-0  ">{t('apropos')}</NavLink>
                  </li>
                  <li className='p-3'>
                      <NavLink to="/contact"  style={menu === "contact" ? styles: null} className="nav-a  font-bold  block py-2 px-3 text-white rounded text-2xl md:hover:bg-transparent md:hover:text-blue-700 md:p-0  ">{t('contacter')}</NavLink>
                  </li>
              </ul>
            </div>
            <div id='navbar' className="relative  bg-green-500 mt-0 sm:mt-32">
              <div className="container">
                <div className="-mx-4 flex flex-wrap">
                  <div className="w-full px-4 lg:w-5/12">
                    <div className="hero-content">
                      <h1 className="mb-5  font-bold !leading-[1.208]  sm:text-[42px] lg:text-[40px] xl:text-5xl">
                          <p className=' text-5xl sm:text-8xl  md:text-9xl lg:text-8xl xl:text-9xl'>{titre1}</p> 
                          <p className='text-5xl sm:text-8xl  md:text-9xl text-end lg:text-8xl xl:text-9xl'>{titre2}</p>
                          <p className='text-5xl sm:text-8xl  md:text-9xl lg:text-8xl  xl:text-9xl'>{titre3}</p>
                      </h1>
                      <p className="max-w-[480px] text-xl text-body-color block text-justify">
                      {t('slogan0')}

                      <NavLink to="https://fr.wikipedia.org/wiki/G%C3%A9otechnique" className='text-[#001cff94]'> {t('link')}</NavLink> 
                      {t('slogan')}
                      <NavLink to="https://fr.wikipedia.org/wiki/G%C3%A9nie_civil" className='text-[#001cff94]'> {t('link1')} </NavLink>   
                      </p>
                    </div>
                  </div>
                  <div className="hidden px-4 lg:block lg:w-1/12"></div>
                  <div className="w-full px-4 lg:w-6/12">
                    <div className="lg:ml-auto lg:text-right">
                      <div   className="relative z-10 inline-block pt-11 lg:pt-0">
                        
                        {menu === 'apropos' ? <AutoPlay></AutoPlay> : menu === 'accueil' ? <AutoPlay1></AutoPlay1> : <img 
                          src={image}
                          alt="hero"
                          className="max-w-full lg:ml-auto animate-fade-left animate-duration-[5000ms] animate-delay-50"
                        />}
                        
                        
                        <span id="service1" className="absolute -bottom-8 -left-8 z-[-1]">
                          <svg 
                            width="93"
                            height="93"
                            viewBox="0 0 93 93"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="2.5" cy="2.5" r="2.5" fill="#3056D3" />
                            <circle cx="2.5" cy="24.5" r="2.5" fill="#3056D3" />
                            <circle cx="2.5" cy="46.5" r="2.5" fill="#3056D3" />
                            <circle cx="2.5" cy="68.5" r="2.5" fill="#3056D3" />
                            <circle cx="2.5" cy="90.5" r="2.5" fill="#3056D3" />
                            <circle cx="24.5" cy="2.5" r="2.5" fill="#3056D3" />
                            <circle cx="24.5" cy="24.5" r="2.5" fill="#3056D3" />
                            <circle cx="24.5" cy="46.5" r="2.5" fill="#3056D3" />
                            <circle cx="24.5" cy="68.5" r="2.5" fill="#3056D3" />
                            <circle cx="24.5" cy="90.5" r="2.5" fill="#3056D3" />
                            <circle cx="46.5" cy="2.5" r="2.5" fill="#3056D3" />
                            <circle cx="46.5" cy="24.5" r="2.5" fill="#3056D3" />
                            <circle cx="46.5" cy="46.5" r="2.5" fill="#3056D3" />
                            <circle cx="46.5" cy="68.5" r="2.5" fill="#3056D3" />
                            <circle cx="46.5" cy="90.5" r="2.5" fill="#3056D3" />
                            <circle cx="68.5" cy="2.5" r="2.5" fill="#3056D3" />
                            <circle cx="68.5" cy="24.5" r="2.5" fill="#3056D3" />
                            <circle cx="68.5" cy="46.5" r="2.5" fill="#3056D3" />
                            <circle cx="68.5" cy="68.5" r="2.5" fill="#3056D3" />
                            <circle cx="68.5" cy="90.5" r="2.5" fill="#3056D3" />
                            <circle cx="90.5" cy="2.5" r="2.5" fill="#3056D3" />
                            <circle cx="90.5" cy="24.5" r="2.5" fill="#3056D3" />
                            <circle cx="90.5" cy="46.5" r="2.5" fill="#3056D3" />
                            <circle cx="90.5" cy="68.5" r="2.5" fill="#3056D3" />
                            <circle  cx="90.5" cy="90.5" r="2.5" fill="#3056D3" />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              <div  className='pt-4 flex justify-center items-center' >
                 <Slider ></Slider>
              </div>
            </div>
          </div>
      </div>
    </>  
    );
  };

export default Hero;


function AutoPlay() {
  
  return (
    <Carousel 
      showArrows={false}
      showStatus={false}
      showThumbs={false}
      infiniteLoop={true}
      autoPlay={true}
      interval={4000}
    >
        <div>
            <img src={image1} alt='imageapropos'/>
        </div>
        <div>
            <img src={image2} alt='imageapropos1' />
        </div>
    </Carousel>
);
}

function AutoPlay1() {
  
  return (
    <Carousel 
      showArrows={false}
      showStatus={false}
      showThumbs={false}
      infiniteLoop={true}
      autoPlay={true}
      interval={4000}
    >
        <div>
            <img src={image2} alt='imageaccueil'/>
        </div>
        <div>
            <img src={image3} alt='imageaccueil1' />
        </div>
    </Carousel>
);
}



function Langue() {

  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState('fr');
  const [isMenuVisible, setIsMenuVisible] = useState(false); // Clearer variable name

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language.flag);
    setIsMenuVisible(false); // Hide menu after selection
    // Update translation library (if needed)
    // Assuming `i18n` is from a translation library like react-i18next
    if (i18n) {
      i18n.changeLanguage(language.flag);
    }
  };

  const languages = [
    { code: 'us', name: 'English', flag: 'us' },
    { code: 'fr', name: 'French', flag: 'fr' },
  ];

  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible); // Toggle visibility
  };

  return (
    <div className="relative inline-block">
      <button
        type="button"
        className="bg-green-500 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
        onClick={toggleMenu}
      >
        <div className="flex items-center text-xl">
          <ReactCountryFlag className="w-4 h-4 mr-2 text-xl" countryCode={selectedLanguage} />
          <span className='text-white'>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <path d="M12 10l5 5 5-5z" />
            </svg>
          </span>
        </div>
      </button>

      {isMenuVisible && (
        <div
          className="absolute z-20 transform transition-all duration-150 ease-in-out origin-top-right right-0 mt-2 p-2 w-20"
          id="language-selector-dropdown"
        >
          <ul className="rounded-lg shadow-lg bg-white">
            {languages.map((language) => (
              <li key={language.code}>
                <button
                  type="button"
                  className="block w-full p-3 text-sm text-gray-900 font-medium hover:bg-slate-300"
                  onClick={() => handleLanguageChange(language)}
                >
                  <span className="flex items-center text-xl">
                    <ReactCountryFlag className="w-4 h-4 mr-2" countryCode={language.code} />
                  </span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}




  



