import Footer from '../component/footer/Footer'
import Header from "../component/header/Header"
import Form from '../component/formulaire/Form'
import contact from '../image/contact.png'
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function Contact(){
   useEffect(() => {
    const id = window.location.hash.substr(1);
    if (id) {
        setTimeout(() => {
            window.scrollTo({top: 700, behavior: 'smooth'});
        }, 100); 
    }else{
        window.scrollTo(0,0); // Scroll to the new position (smooth scrolling not included)
    }
   }, []);
   const {t} = useTranslation()
    return(
        <div>
            <Header titre1={t('nous')} titre2={t("contacte")} image={contact} menu="contact"></Header>
               <div id='form'>
                    <Form></Form>
               </div>
            <Footer></Footer>  
        </div>
    )
}