import Header from "../component/header/Header"
import Footer from "../component/footer/Footer"
import about from '../image/about.png'
import logo from '../image/logo.png'
import GridFooter from "../component/grid/GridFooter"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
export default function About(){
    useEffect(() => {
        const id = window.location.hash.substr(1);
        if (id) {
            setTimeout(() => {
                window.scrollTo({top: 700, behavior: 'smooth'});
            }, 100); 
        }else{
            window.scrollTo(0,0); // Scroll to the new position (smooth scrolling not included)
        }
    }, []);
    const {t}= useTranslation()
    return(
        <div> 
            <Header image={about} titre1={t('qui')} titre2={t('sommes')} menu="apropos"></Header>
            
            
            <div className="parent md:p-10">
                <div className="flex">
                    
                    <div> <p className="md:text-4xl text-2xl text-green-500 p-5 font-bold">{t('Titlehistoire')}</p></div>
                    <div className='comment5'></div>
                </div>

                <div className="bg-white border md:mt-10 mx-5 border-gray-200  md:flex-row  hover:bg-gray-100 ">
                    
                    <div className="flex flex-col items-center  md:flex-row ">
                        <img  className="object-cover w-full rounded-t-lg h-50 md:h-auto md:w-48 md:rounded-lg md:rounded-s-lg p-10" src={logo} alt=""  />
                        <div  className="flex flex-col justify-between p-4 leading-normal">
                            <p  className="mb-2 text-lg tracking-tight text-justify ">
                                {t("histoire")}
                            </p>
                        </div>
                    </div>
                    <p  className="mb-3 sm:m-10 text-lg  font-normal text-black p-2 text-justify">
                        {t("histoire2")}
                    </p>
                </div>

                <div className="flex">
                    
                    <div> <p className="md:text-4xl text-2xl text-green-500 p-5 font-bold">{t('notre clienèle')} </p></div>
                    <div className='comment5'></div>
                </div>
                
                <div className=" m-5 sm:m-10 flex flex-col items-center bg-white border border-gray-200  md:flex-row  hover:bg-gray-100">                    
                     <h5  className="mb-2 text-lg font-normal tracking-tight p-2  text-black text-justify">
                        {t('clientele')}
                    </h5>
                </div> 

                <div className="flex">
                    
                    <div> <p className="md:text-4xl text-2xl text-green-500 p-5 font-bold"> {t('savoir faire')}</p></div>
                    <div className='comment5'></div>
                </div>

                <div className=" m-5 sm:m-10 flex flex-col items-center bg-white border border-gray-200  md:flex-row  hover:bg-gray-100">                    
                     <h5  className="mb-2 text-lg font-normal tracking-tight p-2  text-black text-justify">
                        {t('savoir')}
                    </h5>
                </div> 

                <div className="flex">
                    
                    <div> <p className="md:text-4xl text-2xl text-green-500 p-5 font-bold">{t('nos valeur')}</p></div>
                    <div className='comment5'></div>
                </div>

                <div className=" m-5 sm:m-10 flex flex-col items-center bg-white border border-gray-200  md:flex-row  hover:bg-gray-100">                    
                     <h5  className="mb-2 text-lg font-normal tracking-tight p-2  text-black text-justify">
                        {t('valeur')}
                    </h5>
                </div> 
            </div>
            <GridFooter></GridFooter>
            <Footer></Footer>  
        </div>
    )
}