import Footer from "../component/footer/Footer"
import Header from "../component/header/Header"
import Grids from "../component/grid/Grids"
import service1 from '../image/service1.png'
import service2 from '../image/service2.png'
import service3 from '../image/service3.png'
import service4 from '../image/service4.png'
import service5 from '../image/service5.png'
import service6 from '../image/service6.png'
import service7 from '../image/service7.png'
import service8 from '../image/service8.png'
import service9 from '../image/service9.png'
import accueil from '../image/accueil.jpg'
import cadre from '../image/cadre.png'
import service99 from '../image/service9.jpg'
import service10 from '../image/service10.jpg'
import Slide from "../component/slider/Slide"
import GridFooter from "../component/grid/GridFooter"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"


export default function Home(){
    useEffect(() => {
        const id = window.location.hash.substr(1);
        if (id) {
            setTimeout(() => {
                window.scrollTo({top: 700, behavior: 'smooth'});
            }, 100); 
        }else{
            window.scrollTo(0,0); // Scroll to the new position (smooth scrolling not included)
        }
    }, []);
    
    const {t} = useTranslation();        
    return(
        <div>
           <Header image={accueil} titre1={t('innovation')} titre2={t('sécurité')} titre3={t('expertise')} menu="accueil"></Header>

           <div className="">
                <div className="p-5 text-3xl  text-green-500 font-bold">
                    <div className="flex mb-10 sm:mb-auto text-4xl">
                        <div> <p className="md:text-6xl">{t('avantservice')}</p></div>
                        <div className='comment'></div>
                    </div>

                    <div  className="flex justify-end">
                        <p>{t('nos services')}</p>
                    </div>
                </div>
                <div className="flex flex-wrap ">
                    <div className='w-full md:w-1/3 p-5 ' id="grid"> <Grids  titre={t('service1')} image={service1} lien='service#service1' ></Grids></div>
                    <div className='w-full md:w-1/3 p-5' id="grid"> <Grids titre={t('service2')} image={service2} lien='service#service2'></Grids></div>
                    <div className='w-full md:w-1/3 p-5' id="grid"> <Grids titre={t('service3')} image={service3} lien='service#service3'></Grids></div>
                </div>

                <div className="flex flex-wrap">
                    <div className='w-full md:w-1/3 p-5' id="grid"><Grids titre={t('service4')} image={service4} lien='service#service4'></Grids></div>
                    <div className='w-full md:w-1/3 p-5' id="grid"><Grids titre={t('service5')} image={service5} lien='service#service5'></Grids></div>
                    <div className='w-full md:w-1/3 p-5' id="grid"><Grids titre={t('service6')} image={service6} lien='service#service6'></Grids></div>
                </div>

                <div className="flex flex-wrap">
                    <div className='w-full md:w-1/3 p-5' id="grid"><Grids titre={t('service7')}image={service7} lien='service#service7'></Grids></div>
                    <div className='w-full md:w-1/3 p-5' id="grid"><Grids titre={t('service8')} image={service99} lien='service#service9'></Grids></div>
                    <div className='w-full md:w-1/3 p-5' id="grid"> <Grids titre={t('service9')} image={service10} lien='service#service10'></Grids></div>
                </div>
                <div className="flex flex-wrap">
                    <div className='w-full md:w-1/3 p-5' id="grid"> <Grids titre={t('service10')}image={service8} lien='service#service8'></Grids></div>
                    <div className='w-full md:w-1/3 p-5' id="grid"> <Grids titre={t('service11')} image={service9} contact='/contact' ></Grids></div>
                </div>
                
                <div>
                <div className="flex">  
                    <div className="text-6xl p-5  text-green-500 font-bold">
                        <p>{t('ceo')}</p>
                    </div>
                       <div className='comment2'></div>
                   </div>
                    
                    <div className="flex flex-wrap md:mt-12 justify-center">
                        <img style={{width:400}} className="w-full md:w-1/2 object-cover rounded-t-lg h-50 md:h-auto md:rounded-lg md:rounded-s-lg" src={cadre} alt=""  />
                        <div className="w-full  xl:w-[70%] m-2 xl:m-auto flex flex-col items-center bg-white border border-gray-200  md:flex-row  hover:bg-gray-100">
                            <div  className="flex flex-col justify-between p-4 leading-normal ">
                                    <p  className="mb-3 font-normal text-xl text-gray-700">
                                        <p className="mb-3 text-justify">
                                            {t('motCeo1')}
                                        </p>
                                        <p className="mb-3 text-justify">
                                            {t('motCeo2')}  
                                        </p>
                                        <p className="text-justify">
                                            {t('motCeo3')}
                                        </p>

                                        <p className="font-bold text-center">Mauricette LOUBA</p>
                                    </p>
                            </div>
                        </div> 
                    </div>
                </div>
           </div>
           <GridFooter></GridFooter>
          <Footer></Footer>  
        </div>
    )
}